.app-agua-detalle-ruta {
  text-align: left;
}

.app-agua-detalle-ruta--table {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid var(--color-gris-mas-claro);
  border-radius: var(--sp-chico);
}

.app-agua-detalle-ruta--row {
  display: flex;
  flex-direction: row;
  padding: 5px var(--sp-mediano) 1px var(--sp-mediano);
}

.app-agua-detalle-ruta--row div {
  color: var(--color-negro);
  font-size: 0.9em;
  width: 100%;
}
.app-agua-detalle-ruta--row div:last-child {
  text-align: right;
}

.app-agua-detalle-ruta--row:nth-child(even) {
  background-color: var(--color-gris-mas-claro);
}
