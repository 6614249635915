.app--areas--search {
  margin-bottom: var(--sp-grande);
  padding: var(--sp-mediano);
  border: 1px solid var(--color-gris-mas-claro);
}
.app--areas--item {
  font-family: var(--font-gotham);
  padding: var(--sp-mediano) var(--sp-chico);
  padding-left: var(--sp-mediano);
  border: 1px solid var(--color-gris-mas-claro);
  border-radius: var(--sp-chico);
  margin-bottom: var(--sp-mediano);
  background: linear-gradient(145deg, #f0f0f0, #ffffff);
  transition: all 0.1s var(--std-easing);
  font-size: 1em;
}

.app--areas--item:hover {
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 450px) {
  .app--rutas .app--panel--reclamos {
    width: 50%;
  }
}
