body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-negro: #444;
  --color-gris: #666;
  --color-gris-claro: #bbb;
  --color-gris-mas-claro: #eee;
  --color-azul: #2fb4c5;
  --color-azul-claro: #9fc4cf;
  --color-azul-secundario: #007dac;
  --color-gris-secundario: #585858;
  --color-verde: #cddc41;
  --color-blanco: #fff;
  --color-rojo: #ff0000;
  --color-rojo-claro: #ff6666;
  --color-naranja: #ffa500;
  --sp-chico: 5px;
  --sp-mediano: 15px;
  --sp-grande: 30px;
  --bg-gradient: linear-gradient(
    90deg,
    rgba(47, 180, 197, 1) 0%,
    rgba(0, 125, 172, 1) 100%
  );
  --bg-gradient-darker: linear-gradient(
    90deg,
    rgba(27, 160, 177, 1) 0%,
    rgba(0, 105, 152, 1) 100%
  );

  --cst-easing: cubic-bezier(0.19, 1, 0.22, 1);
  --std-easing: cubic-bezier(0.4, 0, 0.2, 1);
  --emp-easing: cubic-bezier(0.83, 0, 0.17, 1);
  --asc-easing: cubic-bezier(0.4, 0, 1, 1);
  --des-easing: cubic-bezier(0, 0, 0.2, 1);

  --shadow-default: 0px 0px 20px 0px rgba(50, 50, 50, 0.85);
  --shadow-small: 0px 0px 10px 0px rgba(80, 80, 80, 0.35);

  --font-default: "Gotham Rounded", Arial, Helvetica, sans-serif;
  --font-gotham: "Gotham Rounded", Arial, Helvetica, sans-serif;
  --font-sans-serif: "SF Pro Text", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

* {
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", Arial,
    sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-height: 100vh;
}

ul {
  list-style: none;
}

[debug] {
  border: 1px dashed magenta !important;
}

*:focus {
  outline: none;
}

a:-webkit-any-link {
  color: black;
  cursor: pointer;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./fonts/GothamRounded-Bold.woff2") format("woff2"),
    url("./fonts/GothamRounded-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded Book";
  src: url("./fonts/GothamRounded-Book.woff2") format("woff2"),
    url("./fonts/GothamRounded-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("./fonts/GothamRounded-Light.woff2") format("woff2"),
    url("./fonts/GothamRounded-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded Book";
  src: url("./fonts/GothamRounded-BookItalic.woff2") format("woff2"),
    url("./fonts/GothamRounded-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("./fonts/GothamRounded-LightItalic.woff2") format("woff2"),
    url("./fonts/GothamRounded-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("./fonts/GothamRounded-MediumItalic.woff2") format("woff2"),
    url("./fonts/GothamRounded-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("./fonts/GothamRounded-Medium.woff2") format("woff2"),
    url("./fonts/GothamRounded-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
}

[class*="hint--"] {
  position: relative;
  display: inline-block;
}

[class*="hint--"]:before,
[class*="hint--"]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}
[class*="hint--"]:hover:before,
[class*="hint--"]:hover:after {
  visibility: visible;
  opacity: 1;
}
[class*="hint--"]:hover:before,
[class*="hint--"]:hover:after {
  -webkit-transition-delay: 300ms;
  -moz-transition-delay: 300ms;
  transition-delay: 300ms;
}
[class*="hint--"]:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001;
}
[class*="hint--"]:after {
  background: #383838;
  color: white;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 12px;
  white-space: nowrap;
}
[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}
[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label=""]:before,
[aria-label=""]:after,
[data-hint=""]:before,
[data-hint=""]:after {
  display: none !important;
}

/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */
/**
 * set default color for tooltip arrows
 */
.hint--top-left:before {
  border-top-color: #383838;
}

.hint--top-right:before {
  border-top-color: #383838;
}

.hint--top:before {
  border-top-color: #383838;
}

.hint--bottom-left:before {
  border-bottom-color: #383838;
}

.hint--bottom-right:before {
  border-bottom-color: #383838;
}

.hint--bottom:before {
  border-bottom-color: #383838;
}

.hint--left:before {
  border-left-color: #383838;
}

.hint--right:before {
  border-right-color: #383838;
}

/**
 * top tooltip
 */
.hint--top:before {
  margin-bottom: -11px;
}

.hint--top:before,
.hint--top:after {
  bottom: 100%;
  left: 50%;
}

.hint--top:before {
  left: calc(50% - 6px);
}

.hint--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

/**
 * bottom tooltip
 */
.hint--bottom:before {
  margin-top: -11px;
}

.hint--bottom:before,
.hint--bottom:after {
  top: 100%;
  left: 50%;
}

.hint--bottom:before {
  left: calc(50% - 6px);
}

.hint--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}

/**
 * right tooltip
 */
.hint--right:before {
  margin-left: -11px;
  margin-bottom: -6px;
}

.hint--right:after {
  margin-bottom: -14px;
}

.hint--right:before,
.hint--right:after {
  left: 100%;
  bottom: 50%;
}

.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.hint--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

/**
 * left tooltip
 */
.hint--left:before {
  margin-right: -11px;
  margin-bottom: -6px;
}

.hint--left:after {
  margin-bottom: -14px;
}

.hint--left:before,
.hint--left:after {
  right: 100%;
  bottom: 50%;
}

.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hint--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

/**
 * top-left tooltip
 */
.hint--top-left:before {
  margin-bottom: -11px;
}

.hint--top-left:before,
.hint--top-left:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-left:before {
  left: calc(50% - 6px);
}

.hint--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--top-left:after {
  margin-left: 12px;
}

.hint--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}

/**
 * top-right tooltip
 */
.hint--top-right:before {
  margin-bottom: -11px;
}

.hint--top-right:before,
.hint--top-right:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-right:before {
  left: calc(50% - 6px);
}

.hint--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--top-right:after {
  margin-left: -12px;
}

.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

/**
 * bottom-left tooltip
 */
.hint--bottom-left:before {
  margin-top: -11px;
}

.hint--bottom-left:before,
.hint--bottom-left:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-left:before {
  left: calc(50% - 6px);
}

.hint--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--bottom-left:after {
  margin-left: 12px;
}

.hint--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}

/**
 * bottom-right tooltip
 */
.hint--bottom-right:before {
  margin-top: -11px;
}

.hint--bottom-right:before,
.hint--bottom-right:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-right:before {
  left: calc(50% - 6px);
}

.hint--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--bottom-right:after {
  margin-left: -12px;
}

.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

/**
 * source: hint-sizes.scss
 *
 * Defines width restricted tooltips that can span
 * across multiple lines.
 *
 * Classes added:
 * 	1) hint--small
 * 	2) hint--medium
 * 	3) hint--large
 *
 */
.hint--small:after,
.hint--medium:after,
.hint--large:after {
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word;
}

.hint--small:after {
  width: 80px;
}

.hint--medium:after {
  width: 150px;
}

.hint--large:after {
  width: 300px;
}

/**
 * source: hint-always.scss
 *
 * Defines a persisted tooltip which shows always.
 *
 * Classes added:
 * 	1) hint--always
 *
 */
.hint--always:after,
.hint--always:before {
  opacity: 1;
  visibility: visible;
}

.hint--always.hint--top:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--always.hint--top:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

.hint--always.hint--top-left:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--always.hint--top-left:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}

.hint--always.hint--top-right:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--always.hint--top-right:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--always.hint--bottom:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--always.hint--bottom:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}

.hint--always.hint--bottom-left:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--always.hint--bottom-left:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}

.hint--always.hint--bottom-right:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--always.hint--bottom-right:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hint--always.hint--left:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.hint--always.hint--right:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.tag {
  position: relative;
  top: -2px;
  margin-top: -3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7em;
  padding: 3px 8px;
  border-radius: 3px;
}

.tag-baja {
  background: var(--color-gris);
  color: var(--color-blanco);
}
.tag-normal {
  background: var(--color-azul);
  color: var(--color-blanco);
}
.tag-media {
  background: var(--color-naranja);
  color: var(--color-blanco);
}
.tag-alta {
  background: var(--color-rojo);
  color: var(--color-blanco);
}

.debug {
  border: 1px dashed magenta;
}

a {
  text-decoration: none;
  font-size: 0.9rem;
}

a:visited {
  color: inherit;
}

.mbapp-form {
  width: 100%;
}

.mbapp-form .row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}
.mbapp-form .row .inputGroup .react-datepicker-wrapper,
.mbapp-form .row .inputGroup .mbappDatePicker,
.mbapp-form .row .inputGroup input,
.mbapp-form .row .inputGroup {
  width: 100%;
  margin-right: var(--sp-chico);
  padding: var(--sp-chico);
  border-radius: 4px;
}

.mbapp-form .row .inputGroup:last-child {
  margin-right: 0;
}

.mbapp-form .row .section-title {
  width: 100%;
  margin-bottom: var(--sp-mediano);
  font-family: var(--font-gotham);
  color: var(--color-negro);
  font-weight: bold;
  border-top: 1px solid var(--color-gris-claro);
  padding-top: 1em;
}
.mbapp-form .row:first-child .section-title {
  border-top: none;
  padding-top: 0em;
}

@media screen and (min-width: 450px) {
  .mbapp-form .row {
    flex-direction: row;
  }
}

/* Snack fix */
div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 1100;
}

table {
  display: table;
  border-radius: var(--sp-chico);
  border-spacing: 0;
  border-collapse: collapse;
}

table,
th,
td {
  text-align: left;
  border: 1px solid var(--color-gris-mas-claro);
  border: none;
}

.table-header div,
.table-header {
  background: var(--color-verde);
  font-family: var(--font-default);
}

table th {
  padding: var(--sp-chico);
  font-family: var(--font-default);
  color: var(--color-negro);
  font-size: 0.9em;
}

table td {
  font-family: var(--font-sans);
  padding: var(--sp-chico);
  color: var(--color-gris-oscuro);
  font-size: 0.8em;
  padding: 8px var(--sp-chico);
}

table tr:nth-child(even) {
  background: var(--color-gris-mas-claro);
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.text-danger {
  color: var(--color-rojo);
}

.allow-overflow-x {
  overflow-x: auto !important;
}
