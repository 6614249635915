.app {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-blanco);
  border-radius: 20px 20px 0 0;
  height: 100vh;
  max-height: calc(100vh - 50px);
  overflow: hidden;
}

.app--panel {
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.app .app--panel--detalle {
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 10;
  background-color: var(--color-blanco);
  box-shadow: -1px -6px 25px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -1px -6px 25px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: -1px -6px 25px rgba(0, 0, 0, 0.4);
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1) !important;
  height: calc(100% + 2px);
}

.app .app--panel--detalle__show {
  top: 15px !important;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: var(--emp-easing) !important;
}

.app--boton--nuevo__flotando {
  position: fixed;
  bottom: 1em;
  right: var(--sp-grande);
  z-index: 10;
  background: var(--bg-gradient);
  border-radius: 50%;
  border: none;
  color: var(--color-blanco);
  padding: var(--sp-mediano);
  padding-bottom: 0.7em;
  box-shadow: 5px 5px 17px 0px rgba(50, 50, 50, 0.3);
}

.app--panel--container {
  display: flex;
  flex-direction: column;
  padding: var(--sp-mediano);
  padding-bottom: calc(var(--sp-grande) * 2);
  max-height: calc(100vh - 104px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.app--panel--reclamos--container {
  display: flex;
  flex-direction: column;
  padding: var(--sp-mediano);
  padding-bottom: calc(var(--sp-grande) * 2);
  max-height: calc(100vh - 104px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.app--panel--detalle-reclamo--container {
  padding: var(--sp-mediano);
  overflow-y: scroll;
  max-height: calc(100vh - 118px);
  overflow-x: hidden;
}

/* Desktop */
@media screen and (min-width: 450px) {
  .app {
    flex-direction: row;
    background-color: var(--color-blanco);
    border-radius: 0;
    padding: 0;
    max-height: calc(100vh - 50px);
    height: calc(var(--vh, 1vh) * 100);
  }

  .app--panel {
    padding-left: 0;
    overflow: hidden;
  }

  .app--boton--nuevo__flotando {
    display: none;
  }

  .toolbar147--nuevo {
    background: var(--bg-gradient);
    color: var(--color-blanco);
    padding-top: 0.25em;
    margin-right: 0;
  }

  .app--panel:nth-child(2) {
    border-left: 2px solid var(--color-azul-secundario);
    overflow: hidden;
  }

  .app .app--panel--detalle {
    opacity: 1;
    position: static;
    border-radius: 0;
    box-shadow: none;
    max-height: 100%;
  }

  .app--panel--reclamos--container {
    padding: var(--sp-grande) var(--sp-mediano);
    max-width: 100%;
    padding-right: var(--sp-mediano);
    height: 100% !important;
  }

  .app--panel--detalle-reclamo--container {
    max-height: calc(100vh - 104px);
    overflow-x: hidden;
  }

  .toolbar147--nuevo--container {
    padding-top: 3px;
  }
}
