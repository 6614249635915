.toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #ddd;
  padding: 1em var(--sp-mediano) 0.5em var(--sp-mediano);
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  max-width: 100vw;
  background-color: var(--color-blanco);
  color: var(--color-negro);
  /*box-shadow: -0px 18px 15px #fff;*/
  height: 3.7em;
}

.toolbar label:first-child {
  display: block;
  width: 100%;
  padding-top: 0.25em;
  text-align: left;
}

.toolbar div {
  flex-grow: 1;
  min-width: 3em;
}

.toolbar div:first-child {
  flex-grow: 8;
}

.toolbar label,
.toolbar .dropdown label {
  font-family: var(--font-default);
  font-weight: bold;
}

.toolbar--dropdown .dropdown {
  color: var(--color-negro);
  max-height: 10px;
  overflow-y: visible;
}
.toolbar--dropdown .dropdown:after {
  content: " ⌄";
  position: relative;
  top: -2px;
}
.toolbar .toolbar--nuevo {
  display: none;
}

.toolbar .btn-action {
  margin-top: -0.4em;
  margin-right: var(--sp-mediano);
}

.toolbar label {
  padding-top: 0.3em;
}

.toolbar .combobox {
  flex-direction: row;
  margin-left: 5em;
}
.toolbar .combobox label {
  text-align: right;
  padding: 0;
  background: none;
  left: -3.5em;
  top: 0.3em;
  display: block;
  min-width: 3em;
}

.toolbar .combobox input {
  border: 1px solid red !important;
}

@media screen and (min-width: 450px) {
  .toolbar {
    padding-left: var(--sp-mediano);
    padding-right: 0;
  }

  .toolbar .toolbar--nuevo {
    display: block;
    margin-top: -7px;
    padding-bottom: 5px;
  }

  .toolbar--nuevo .toolbar--button--icon {
    position: relative;
    display: inline;
    margin-right: var(--sp-chico);
    bottom: -3px;
  }
}
